/** 2024 IRMAA Brackets */
export const brackets = {
  individual_bracket: [
    {
      id: 1,
      amount: '$103,000 or less',
      monthly_payment: 174.7,
      lower_bound: 0,
      upper_bound: 103000,
    },
    {
      id: 2,
      amount: 'above $103,000 up to $129,000',
      monthly_payment: 244.6,
      lower_bound: 103000,
      upper_bound: 129000,
    },
    {
      id: 3,
      amount: 'above $129,000 up to $161,000',
      monthly_payment: 349.4,
      lower_bound: 129000,
      upper_bound: 161000,
    },
    {
      id: 4,
      amount: 'above $161,000 up to $193,000',
      monthly_payment: 454.2,
      lower_bound: 161000,
      upper_bound: 193000,
    },
    {
      id: 5,
      amount: 'above $193,000 and less than $500,000',
      monthly_payment: 559.0,
      lower_bound: 193000,
      upper_bound: 500000,
    },
    {
      id: 6,
      amount: '$500,000 or above',
      monthly_payment: 594.0,
      lower_bound: 500000,
      upper_bound: 10000000,
    },
  ],
  joint_bracket: [
    {
      id: 1,
      amount: '$206,000 or less',
      monthly_payment: 174.7,
      lower_bound: 0,
      upper_bound: 206000,
    },
    {
      id: 2,
      amount: 'above $206,000 up to $258,000',
      monthly_payment: 244.6,
      lower_bound: 206000,
      upper_bound: 258000,
    },
    {
      id: 3,
      amount: 'above $258,000 up to $322,000',
      monthly_payment: 349.4,
      lower_bound: 258000,
      upper_bound: 322000,
    },
    {
      id: 4,
      amount: 'above $322,000 up to $386,000',
      monthly_payment: 454.2,
      lower_bound: 322000,
      upper_bound: 386000,
    },
    {
      id: 5,
      amount: 'above $386,000 and less than $750,000',
      monthly_payment: 559.5,
      lower_bound: 386000,
      upper_bound: 750000,
    },
    {
      id: 6,
      amount: '$750,000 and above',
      monthly_payment: 594.0,
      lower_bound: 750000,
      upper_bound: 10000000,
    },
  ],
};
